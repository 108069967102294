import React from 'react';
import { Box, Image } from 'grommet';
import { navigate } from 'gatsby'
import { Button } from '@appkit4/react-components/button';
import SidebarCDIR from '../../components/SidebarCDIR';

export default function PersonaCDIR() {
  const onClickButton_CDIRMyLearning = () => {
    navigate ('/cyber-defense-incident-responder')
  }
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'92vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarCDIR />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/Xyy0Tw1BRpJTTh8zhBGiH/03d2ad81ca2f857f77828c6a86e0afcf/dis-pe-cdir-explore.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} useMap="#discover-new" />
            <Button style={{width: '80px', position: 'absolute', top: '8px', left: '22%'}} kind='text' onClick={onClickButton_CDIRMyLearning} ><div className='beacon'></div>&nbsp; &nbsp; </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}